@import '~antd/dist/antd.css';

.App {
  margin: 24px auto;
  max-width: 750px;
}

.main {
  width: 100%;
}

.hero-icon {
  width: 60px;
  height: 60px;
  margin-right: 10px;
}

.equip {
  height: 90px;
}
